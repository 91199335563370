export default {
  translation: {
    'xCloud': '雲遊戲',
    'Configuration obtained successfully, initiating offer...': '獲取配置成功，開始發起offer...',
    'Remote offer retrieved successfully...': '遠程offer獲取成功...',
    'Ready to send ICE...': '準備發送ICE...',
    'Exchange ICE successfully...': 'ICE交換成功, 正在建立連接...',
    'Configuring ICE, waiting for response...': '正在配置ICE，正在等待響應...',
    'Connected': '連接成功',
    'Connecting...': '正在連接...',
    'Disconnecting...': '正在斷開連接...',
    'Toggle Performance': '顯示/隱藏效能資訊',
    'Toggle Virtual Gamepad': '顯示/隱藏虛擬手柄',
    'Disconnect': '斷開連接',
    'Key Maping': '按鍵映射',
    'Please press the button on the controller, which will be mapped to:': '請按手柄上的按鍵，該按鍵將映射為:',
    'After successful mapping, this pop-up will automatically close': '映射成功後將自動關閉此彈窗',
    'Cancel': '取消',
    'Save': '儲存',
    'Saving...': '儲存中...',
    'Saved': '儲存成功',
    'Reset': '恢復預設值',
    'Please press the button on the gamepad; this button will be mapped to:': '請按手柄上的按鍵，該按鍵將映射為:',
    'After successful mapping, this popup will automatically close.': '映射成功後將自動關閉此彈窗',
    'Warning': '提示',
    'The terminal seems to have been without a screen for a long time. Please try reconnecting.': '終端似乎很久沒有返回畫面，可以嘗試點擊確定按鍵刷新串流憑據並重新連接。',
    'NAT traversal failed. If you were attempting remote streaming and it was successful before, please click the refresh button below to refresh the streaming credentials and try again.': 'NAT穿透失敗，如果你是嘗試遠程串流並之前成功過，請點擊下方刷新按鈕刷新串流憑據重試，重試多次依舊無法連接請稍後再試。',
    'Keep waiting': '繼續等待',
    'Exit': '退出',
    'Refresh': '刷新',
    'Resolution': '解析度',
    RTT: '延遲',
    FPS: '幀數',
    FD: '掉幀',
    PL: '丟包',
    Bitrate: '碼率',
    DT: '解碼',
  }
}